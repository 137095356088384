import "./button.scss"

import React from "react"
import { Link } from "gatsby"

const Button = ({ to, children, className }) => {
  return (
    <Link to={to} className={`button ${className ? className : ""}`}>
      {children}
    </Link>
  )
}

export default Button
