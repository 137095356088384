import "styles/pages/404.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import Button from "components/Button/Button"

const NotFoundPage = () => {
  const title = "Búrøkt"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="page-404">
        <p>Heimasíðan hjá Búrøkt kemur skjótt</p>
        <Button to="/">Heim</Button>
      </section>
    </Layout>
  )
}

export default NotFoundPage
